import axios from 'axios';
import { AxiosInstance } from 'axios';

export default class ActivateService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios;
  }

  public initResetPassword(email: string): Promise<any> {
    return this.axios.post('api/account/reset-password/init', email, {
      headers: {
        'content-type': 'text/plain',
      },
    });
  }

  public finishResetPassword(resetKey: string, newPassword: string): Promise<any> {
    return this.axios.post('api/account/reset-password/finish', {
      key: resetKey,
      newPassword: newPassword,
    });
  }
}
