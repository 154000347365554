import axios from 'axios';
import { IUser } from '@/shared/model/user.model';
import { BaseService } from '@/core/base-service';

export default class UserManagementService extends BaseService<IUser> {
  public constructor() {
    super('api/admin/users');
  }

  public retrieveAuthorities(): Promise<any> {
    return axios.get('api/authorities');
  }

  public update(object: IUser): Promise<any> {
    return axios.put(this.endpointName, object);
  }
}
