import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class PropertyService extends BaseService<any> {
  public constructor() {
    super('api/public/ghmoto/property');
  }

  public checkLastUpdate(): Promise<any> {
    return axios.get(this.endpointName + `/check-last-update`);
  }
}
