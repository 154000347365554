import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class MylaptimeService extends BaseService<any> {
  public constructor() {
    super('api/public/ghmoto/times/mylaptime');
  }

  public downloadResults(req?: any): Promise<any> {
    const filterParam = req && req.filterParam ? req.filterParam : '';
    return axios.get('api/public/ghmoto/times/download-results' + filterParam, { responseType: 'blob' });
  }
}
