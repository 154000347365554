import axios from 'axios';
import dayjs from 'dayjs';

import primevueDe from '@i18n/de/primevue.json';
import primevueEn from '@i18n/en/primevue.json';

export default class TranslationService {
  public refreshTranslation(i18n: any, store: any, primevue: any, newLanguage: string) {
    let currentLanguage = store.getters.currentLanguage;
    // currentLanguage = newLanguage ? newLanguage : 'de';
    currentLanguage = 'en';

    if (i18n && !i18n.messages[currentLanguage]) {
      i18n.setLocaleMessage(currentLanguage, {});
      axios.get(`i18n/${currentLanguage}.json?_=${I18N_HASH}`).then((res: any) => {
        if (res.data) {
          i18n.setLocaleMessage(currentLanguage, res.data);
          this.setLocale(currentLanguage, store, i18n);
        }
      });
    } else if (i18n) {
      this.setLocale(currentLanguage, store, i18n);
    }

    if (primevue && primevue.config) {
      primevue.config.locale = currentLanguage === 'en' ? primevueDe : primevueEn;
    }
  }

  private setLocale(lang: string, store, i18n) {
    dayjs.locale(lang);
    i18n.locale.value = lang;
    store.commit('currentLanguage', lang);

    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
  }
}
