export default function buildPaginationQueryOpts(req) {
  if (req && req.paginationQuery) {
    let sorts = '';
    for (const idx of Object.keys(req.paginationQuery.sort)) {
      if (sorts.length > 0) {
        sorts += '&';
      }
      sorts += 'sort=' + req.paginationQuery.sort[idx];
    }
    return `?${sorts}&page=${req.paginationQuery.page}&size=${req.paginationQuery.size}`;
  }
  return '';
}
