const Activate = () => import('@/account/activate/activate.vue');
const ResetPasswordInit = () => import('@/account/reset-password/init/reset-password-init-modal.vue');
const ResetPasswordFinish = () => import('@/account/reset-password/finish/reset-password-finish.vue');

export default [
  {
    path: '/account/activate',
    name: 'Activate',
    component: Activate,
  },
  //Change Password: change-password-modal.vue
  //Register: register-modal
  {
    path: '/account/reset/init',
    name: 'ResetPasswordInit',
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinish,
  },
];
