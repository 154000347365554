import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class DocumentService extends BaseService<any> {
  public constructor() {
    super('api/admin/ghmoto/document');
  }

  public deleteSelected(selectedIds: any): Promise<any> {
    return axios.post(`${this.endpointName}/delete-selected`, selectedIds);
  }
}
