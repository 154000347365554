import { Module } from 'vuex';

export interface ProjectStateStorable {
  driverNumber: string;
}

function initDriverNumber(): string {
  return localStorage.getItem('driver-number');
}

export const defaultProjectState: ProjectStateStorable = {
  driverNumber: initDriverNumber(),
};

export const projectStore: Module<ProjectStateStorable, any> = {
  state: { ...defaultProjectState },
  getters: {
    driverNumber: state => state.driverNumber,
  },
  mutations: {
    setDriverNumber(state, driverNumber) {
      state.driverNumber = driverNumber;
      localStorage.setItem('driver-number', driverNumber);
    },
  },
};
