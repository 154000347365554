const BestlapallridersComponent = () => import('@/project/bestlapallriders/bestlapallriders-component.vue');
const DocumentComponent = () => import('@/project/document/document-management.vue');
const GridsComponent = () => import('@/project/grids/grids-component.vue');
const InfoComponent = () => import('@/project/info/info-component.vue');
const MylaptimesListComponent = () => import('@/project/mylaptimes/mylaptimes-list-component.vue');
const MylaptimesSelectComponent = () => import('@/project/mylaptimes/mylaptimes-select-component.vue');
const RaceliveComponent = () => import('@/project/racelive/racelive-component.vue');
const RaceresultsComponent = () => import('@/project/raceresults/raceresults-component.vue');
const TimescheduleComponent = () => import('@/project/timeschedule/timeschedule-component.vue');

export default [
  {
    path: '/info',
    name: 'Info',
    component: InfoComponent,
  },
  {
    path: '/admin/documents',
    name: 'Documents',
    component: DocumentComponent,
  },
  {
    path: '/',
    name: 'Bestlapallriders',
    component: BestlapallridersComponent,
  },
  {
    path: '/grids',
    name: 'Grids',
    component: GridsComponent,
  },
  {
    path: '/mylaptimes',
    name: 'Mylaptimesselect',
    component: MylaptimesSelectComponent,
  },
  {
    path: '/mylaptimeslist',
    name: 'Mylaptimeslist',
    component: MylaptimesListComponent,
  },
  {
    path: '/racelive',
    name: 'Racelive',
    component: RaceliveComponent,
  },
  {
    path: '/raceresults',
    name: 'RaceResults',
    component: RaceresultsComponent,
  },
  {
    path: '/timeschedule',
    name: 'TimeSchedule',
    component: TimescheduleComponent,
  },
];
