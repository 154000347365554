import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class DocumentPublicService extends BaseService<any> {
  public constructor() {
    super('api/public/ghmoto/document');
  }

  public getDocument(id: any): Promise<any> {
    return axios.get('api/public/ghmoto/document' + `/${id}`);
  }
}
