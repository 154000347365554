// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import App from './app.vue';
import Vue3TouchEvents from 'vue3-touch-events';
import mitt from 'mitt';
import { FilterService } from 'primevue/api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import { initPrimeVue } from '@/config/config-primevue';
import { initRouter } from '@/router';
import { reactive } from 'vue';

import AccountService from '@/account/account.service';
import ActivateService from '@/account/activate/activate.service';
import ChangePasswordService from '@/account/change-password/change-password.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import GatewayService from '@/admin/gateway/gateway.service';
import HealthService from '@/admin/health/health.service';
import LogsService from '@/admin/logs/logs.service';
import MetricsService from '@/admin/metrics/metrics.service';
import RegisterService from '@/account/register/register.service';
import ResetPasswordService from '@/account/reset-password/reset-password.service';
import ToastService from 'primevue/toastservice';
import TranslationService from '@/locale/translation.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import DocumentPublicService from '@/project/services/document-public.service';
import DocumentService from '@/project/services/document.service';
import DriverService from '@/project/services/driver.service';
import LogoService from '@/project/services/logo.service';
import PropertyService from '@/project/services/property.service';
import BestlapallridersService from '@/project/services/bestlapallriders.service';
import LaptimeDaysService from '@/project/services/laptimedays.service';
import MylaptimeService from '@/project/services/mylaptime.service';
import RaceliveService from '@/project/services/racelive.service';
import RaceclassesService from '@/project/services/raceclasses.service';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import { AlertService } from '@rednex/gateway_core';
import { AlertServiceConfiguration } from '@rednex/gateway_core';
import { JhiSortIndicator } from '@rednex/gateway_core';
import { initFortAwesome } from '@rednex/gateway_core';
import { initI18N } from '@rednex/gateway_core';
import { initVueXStore } from '@rednex/gateway_core';
import { projectStore } from '@/config/store/project-store';

import '../content/scss/global.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@rednex/gateway_core/componentstyles';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';

const emitter = mitt();
const i18n = initI18N();
const router = initRouter();
const store = initVueXStore({ projectStore });

const translationService = new TranslationService();
const accountService = new AccountService(store, router);
const alertService = new AlertService(new AlertServiceConfiguration(false, 0, 5000, 5000, 10000));

router.beforeEach(async (to, from, next) => {
  const salesMode = store.getters.salesMode;

  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && (to.meta.authorities as []).length > 0) {
    await accountService.reloadAuthentication(to.meta.authorities).then(authenticated => {
      if (!authenticated) {
        sessionStorage.setItem('requested-url', to.fullPath);

        if (to.fullPath !== '' && to.fullPath !== '/') {
          next('/forbidden');
        } else {
          next();
        }
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
export const app = createApp({
  el: '#app',
  components: { App } as any,
  template: '<App/>',
  provide: {
    accountService: accountService,
    activateService: new ActivateService(),
    alertService: alertService,
    changePasswordService: new ChangePasswordService(),
    configurationService: new ConfigurationService(),
    gatewayService: new GatewayService(),
    healthService: new HealthService(),
    logsService: new LogsService(),
    metricsService: new MetricsService(),
    registerService: new RegisterService(),
    resetPasswordService: new ResetPasswordService(),
    translationService: translationService,
    userManagementService: new UserManagementService(),
    documentPublicService: new DocumentPublicService(),
    documentService: new DocumentService(),
    driverService: new DriverService(),
    logoService: new LogoService(),
    propertyService: new PropertyService(),
    bestlapallridersService: new BestlapallridersService(),
    laptimeDaysService: new LaptimeDaysService(),
    mylaptimeService: new MylaptimeService(),
    raceliveService: new RaceliveService(),
    raceclassesService: new RaceclassesService(),
  },
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.router = router;
app.config.globalProperties.$appState = reactive({ themeMode: 'light' });

app.use(Vue3TouchEvents);
app.use(i18n);
app.use(router);
app.use(store);
app.use(ToastService);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('jhi-sort-indicator', JhiSortIndicator);

initFortAwesome();
initPrimeVue(app);

FilterService.register('DATEEQUALS', (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }

  return new Date(value).toLocaleDateString() === filter.toLocaleDateString();
});

FilterService.register('BOOLEANBADGECHECK', (value, filterArray): boolean => {
  if (filterArray === undefined || filterArray === null || filterArray.length === 0) {
    return true;
  }

  const selected = Array.from(filterArray, data => {
    return (data as any).value.toString();
  });

  return selected.includes(value.toString());
});

setupAxiosInterceptors(
  unauthenticatedError => {
    const error = unauthenticatedError;
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      if (!url.equals('/')) {
        store.commit('logout');
      }

      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        router.push('/');
        return;
      }
    }
    console.error('unauthenticatedError:', error);
    return Promise.reject(error);
  },
  badRequestError => {
    console.error('badRequestError:', badRequestError);
    return Promise.reject(badRequestError);
  },
  internalServerError => {
    console.error('internalServerError:', internalServerError);
    return Promise.reject(internalServerError);
  },
);

app.mount('#app');
