import axios from 'axios';
import { AxiosInstance } from 'axios';

export default class ActivateService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios;
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return this.axios.post('api/account/change-password', { currentPassword, newPassword });
  }
}
