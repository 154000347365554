import { computed } from '@vue/reactivity';
import { maxLength } from '@vuelidate/validators';
import { minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
import { required } from '@vuelidate/validators';
import { sameAs } from '@vuelidate/validators';

export const defaultObject = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const validationState = reactive({
  ...defaultObject,
});

export const validationRules = computed(() => {
  return {
    currentPassword: {
      required,
    },
    newPassword: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(254),
    },
    confirmPassword: {
      required,
      sameAs(newPassword: any) {},
    },
  };
});
