import { BaseService } from '@/core/base-service';
import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

export default class RaceclassesService extends BaseService<any> {
  public constructor() {
    super('api/public/ghmoto/raceclasses');
  }

  public retrieveRaceclassesAll(req?: any): Promise<any> {
    const filterParam = req && req.filterParam ? req.filterParam : '';
    return axios.get(this.endpointName + '/all' + filterParam + buildPaginationQueryOpts(req));
  }

  public retrieveRaceclassesRace(req?: any): Promise<any> {
    const filterParam = req && req.filterParam ? req.filterParam : '';
    return axios.get(this.endpointName + '/race' + filterParam + buildPaginationQueryOpts(req));
  }
}
